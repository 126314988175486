import mp4Video from './assets/ARD_Animation.mp4';
import webMVideo from './assets/ARD_Animation.webm';
import { ReactComponent as AlarmWarningIcon } from './assets/alarm-warning.svg';
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './App.css';

const SUBSCRIBE_ENDPOINT = "http://165.227.169.216:13000/api/subscribe"

function App() {
  return (
    <div className="App">
      <Helmet>
        <title>Join the Revolution | ARdealy Prelaunch</title>
      </Helmet>
      <Header />
      <MainContent>
        <InteractiveDesignLab />
        <VideoComponent />
      </MainContent>
    </div>
  );
}

function Header() {
  return <header className="App-header"></header>;
}

function MainContent({ children }) {
  return <main className="App-main">{children}</main>;
}

function InteractiveDesignLab() {
  const animationDirection = (index) => index % 2 === 0 ? 'fadeInMoveRight' : 'fadeInMoveLeft';
  const features = ["real-time visualization", "comprehensive catalog", "interactive design lab", "seamless integration"];

  // Function to calculate color stop for each feature
  const calculateColor = (index) => {
    const percentage = index / (features.length - 1);
    const startColor = [65, 182, 255];
    const endColor = [0, 90, 255];
    const color = startColor.map((start, i) => {
      const end = endColor[i];
      const channel = start + percentage * (end - start);
      return Math.round(channel);
    });
    return `rgb(${color.join(',')})`;
  };

  return (
    <section className="DesignLab">
      <h1>get ready...</h1>
      <ul>
        {features.map((feature, index) => (
          <li key={index} style={{
            color: calculateColor(index),
            animation: `${animationDirection(index)} 2s ease forwards`
          }}>
            {feature}
          </li>
        ))}
      </ul>
      <SignUpComponent />
    </section>
  );
}

function VideoComponent() {
  return (
    <div className="VideoComponent">
      <video autoPlay={true} muted={true} loop={true} playsInline={true}>
      <source src={webMVideo} type="video/webm" />
        <source src={mp4Video} type="video/mp4" />
      </video>
    </div>
  );
}

// function SignUpComponent() {
//   const [email, setEmail] = useState('');
//   const [error, setError] = useState('');
//   const [successMessage, setSuccessMessage] = useState('');

//   // Basic email validation function
//   const isValidEmail = (email) => {
//     return /\S+@\S+\.\S+/.test(email);
//   };

//   const handleEmailChange = (e) => {
//     setEmail(e.target.value);
//     if (error) setError('');
//     if (successMessage) setSuccessMessage('');
//   };

//   const handleSubmit = async (e) => {
//     e.preventDefault();

//     if (!isValidEmail(email)) {
//       setError('A valid email needs to be provided.');
//       return;
//     }

//     try {
//       const response = await fetch(SUBSCRIBE_ENDPOINT, {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/json',
//         },
//         body: JSON.stringify({ email }),
//       });

//       if (!response.ok) {
//         throw new Error('Subscription failed. Please try again later.');
//       }

//       const data = await response.json();
//       console.log('Subscription successful:', data);
//       setEmail('');
//       setError('');
//       setSuccessMessage(data.message);

//       // TODO : Show success to user
//     } catch (error) {
//       console.error('Error submitting email:', error);
//       setError(error.message);
//     }
//   };

//   return (
//     <div className="SignUp">
//       <form onSubmit={handleSubmit}>
//         <input
//           type="email"
//           placeholder="Enter your email"
//           value={email}
//           onChange={handleEmailChange}
//         />
//         <button class="SignInButton">
//           Go!&nbsp;🚀
//         </button>
//         {error && <div className="errorText">{error}</div>}
//         {successMessage && <div className="successMessage">{successMessage}</div>}
//       </form>
//     </div>
//   );
// }


function SignUpComponent() {
  const handleCalendlyClick = () => {
    window.open('https://calendly.com/ardealy/30min', '_blank');
  };

  return (
    <div className="SignUp">
      <button className="SignInButton" onClick={handleCalendlyClick}>
      Schedule time with us 🚀
      </button>
    </div>
  );
}


export default App;